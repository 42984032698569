$c: '.header';
$headerBgColor: #eee;

#{$c} {
    background-color: $headerBgColor;

    .wrapper-menu {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: rem(var(--header-h));

        @include media('>=medium') {
            justify-content: flex-start;
        }
    }

    .logo {
        display: block;
        height: 100%;

        @include media('>=medium') {
            max-width: rem(150px);
            height: auto;
        }

        img {
            display: block;
            height: 100%;

            @include media('>=medium') {
                height: auto;
            }
        }
    }

    &__main-nav {
        position: absolute;
        overflow: auto;
        top: rem(var(--header-h));
        left: 0;
        width: 100%;
        background: $headerBgColor;
        z-index: 2;

        @include media('>=medium') {
            display: block !important;
            position: relative;
            overflow: inherit;
            top: auto;
            left: auto;
            width: auto;
        }

        &__inner {
            height: calc(100vh - var(--header-h));
            padding: rem(20px);

            @include media('>=medium') {
                height: auto;
                padding: 0;
            }
        }
    }

    &__ul-wrapper {
        > ul {
            @extend %list--unstyled;
            text-align: center;

            @include media('>=medium') {
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }

            #{$c}__li-level-1 {
                position: relative;
                padding: rem(20px);

                @include media('>=medium') {
                    padding: 0 rem(20px);
                }

                .btn-expand {
                    position: absolute;
                    top: rem(24px);
                    right: 0;
                    border: none;
                    padding: 0;
                    transition: ease .3s;
                    transform-origin: center;
                    transform: rotate(-90deg);

                    @include media('>=medium') {
                        top: 50%;
                        transform: translate(0, -50%);
                    }
                }

                a {
                    text-decoration: none;
                }
            }

            #{$c}__level-wrapper {
                position: fixed !important;
                overflow: auto;
                top: rem(var(--header-h));
                left: 100%;
                width: 100%;
                height: calc(100% - var(--header-h));
                padding: rem(20px);
                transition: ease .3s;
                background: $headerBgColor;
                z-index: 2;

                @include media('>=medium') {
                    display: none;
                    position: absolute !important;
                    top: 100%;
                    left: 0;
                    width: auto;
                    min-width: rem(100px);
                    height: auto;
                    padding: rem(15px) 0;
                    background: $white;
                    border: 1px solid $headerBgColor;
                    overflow: visible;
                }

                &.show {
                    transform: none !important;
                    inset: rem(var(--header-h)) auto auto 0 !important;

                    @include media('>=medium') {
                        inset: auto auto auto 0 !important;
                        display: block;
                    }
                }

                #{$c}__list-links {
                    @extend %list--unstyled;

                    .header__li-level {
                        position: relative;
                        padding: rem(20px);
                        margin-bottom: rem(8px);
                        text-align: center;

                        @include media('>=medium') {
                            padding: 0 rem(15px);
                        }

                        &__back {
                            @include media('>=medium') {
                                display: none;
                            }

                            .btn-back {
                                position: absolute;
                                top: rem(20px);
                                left: rem(20px);
                                padding: 0;
                                border: none;

                                [class^='icon'] {
                                    display: inline-block;
                                    vertical-align: baseline;
                                    transform: rotate(90deg);
                                }
                            }
                        }

                        a {
                            text-decoration: none;
                        }
                    }
                }
            }

            #{$c}__lk-level-2 {
                .dropdown-menu {
                    @include media('>=medium') {
                        top: 0 !important;
                        left: 100% !important;
                    }
                }
            }
        }
    }
}
