/* ------------------------------------------------
- Ici, tous les styles relatifs à la structure de la page. La base, la 'coquille' du site.
------------------------------------------------ */

html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    @include scrollbar(6px);
    //background à remplir pour l'accessibilité
    background-color: $white;
    min-width: 320px;
    color: $color-text;
    overflow-x: hidden;
}

.site-content {
    @include site-content;
}

.skip-links {
    @extend %list--unstyled;
    margin: 0;

    li {
        margin: 0;
        padding: 0;
    }

    a {
        position: absolute;
        left: -50000px;

        &:focus {
            z-index: 100;
            position: static;
            left: auto;
        }
    }
}

/* ============================== */
/* DRUPAL CONTENT */
/* ============================== */
.page-content-wrapper {
    @include clearfix;

    .site-content {
        &.-main {
            @include last-child-no-margin;
            padding-top: rem($spacer-xl);
            padding-bottom: rem($spacer-xl);

            @include media('<medium') {
                padding-top: rem(-$spacer-l);
                padding-bottom: rem(-$spacer-l);
            }

            > * {
                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }
}

.wysiwyg-content {
    > * {
        &:first-child {
            margin-top: 0;
            padding-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }
}

.bk-section {
    overflow: auto;
}

// intent & purpose ?
.paragraph {
    max-width: rem($sc-max-width);
    margin: rem($spacer-l) auto;
    padding: 0 rem($sc-x-padding);

    .site-content & {
        max-width: none;
        padding: 0;
    }

    @include media('<medium') {
        padding: 0 rem($sc-x-padding-s);
    }

    @include media('<tablet') {
        margin: rem($spacer-m) auto;
    }

    &--hp {
        margin: 0 auto 6.4rem;

        @include media('<tablet') {
            margin: 0 auto 4rem;
        }
    }

    &--action {
        display: flex;
        justify-content: center;
        gap: 2rem;
        margin-bottom: 20rem;

        @include media('<tablet') {
            flex-direction: column;
            margin-bottom: 6rem;
        }
    }
}

// intent & purpose ?
.reduced-content {
    max-width: rem(795px) !important; // shouldn't it be related to a breakpoint variable?
    margin-left: auto;
    margin-right: auto;

    &-xs {
        max-width: rem(480px) !important; // shouldn't it be related to a breakpoint variable?
    }
}

.open-nav {
    @include media('<desktop') {
        overflow: hidden;
    }
}
