$a: '.hero-block';

#{$a} {
    height: 45.4rem;
    overflow: hidden;
    border-radius: 0 0 5rem 5rem;
    position: relative;
    box-shadow: 0 1rem 6rem 0 rgba(193, 201, 214, .45);
    margin: 0 0 9.5rem;
    max-height: 50vh;

    @include media('<tablet') {
        height: 39rem;
        border-radius: 0 0 3rem 3rem;
        margin: 0 0 4.8rem;
    }

    @include media('>desktop') {
        height: 60rem;
    }

    .page-title {
        //ff gotham xnarrow
        color: #{$white};
        font-weight: 400;
        font-size: 5.6rem;
        line-height: 6.4rem;
        margin: 0;

        &--wrapper {
            position: absolute;
            bottom: 8rem;
            left: 0;
            right: 0;
            z-index: 2;
            margin: 0 auto;
            width: 100%;
            max-width: 118rem;
            padding: 0 3rem;

            @include media('<tablet') {
                bottom: 3rem;
            }
        }

        strong {
            text-transform: uppercase;
            font-size: 6.4rem;
        }

        @include media('<tablet') {
            font-size: 3.2rem;
            line-height: 3.84rem;

            strong {
                font-size: 5.6rem;
                line-height: 6.72rem;
            }
        }

        @include media('<mobile') {
            strong {
                font-size: 4rem;
                line-height: 5rem;
            }
        }
    }

    img {
        height: 100%;
        object-fit: cover;
        object-position: top;
    }

    .logo {
        position: absolute;
        top: 4.8rem;
        left: 4.6rem;
        z-index: 2;

        @include media('<tablet') {
            top: 3rem;
            left: 2rem;

            img {
                width: 5.9rem;
                height: 4.2rem;
            }
        }
    }

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1;
        background: linear-gradient(360deg, rgba(0, 0, 0, .6) 0, rgba(0, 0, 0, 0) 50%);
    }
}
