$d: '.btn-menu';
$btn-menu-color: $white;
$btn-menu-width: 2.8rem;
$btn-menu-height: 2rem;

.navbar-collapse {
    @include media('<desktop') {
        display: none;
        position: fixed;
        top: 6.8rem;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100vw;
        z-index: 1000;
        background-color: inherit;
        padding: 1.6rem 3.2rem;
    }
}

#{$d} {
    background: none;
    border: 0;
    display: none;
    height: $btn-menu-height;
    width: $btn-menu-width;
    cursor: pointer;
    transition-timing-function: linear;
    transition-duration: .15s;
    transition-property: opacity, filter;
    text-transform: none;
    position: absolute;
    left: 1.6rem;
    top: 0;
    bottom: 0;
    margin: auto;
    padding: 0;

    > * {
        pointer-events: none;
    }

    @include media('<desktop') {
        display: block;
    }

    &[aria-expanded='true'] {
        #{$d}-inner {
            transition-delay: .12s;
            transition-timing-function: cubic-bezier(.215, .61, .355, 1);
            transform: rotate(45deg);

            &:before {
                top: 0;
                transition: top 75ms ease, opacity 75ms ease .12s;
                opacity: 0;
            }

            &:after {
                bottom: 0;
                transition: bottom 75ms ease, transform 75ms cubic-bezier(.215, .61, .355, 1) .12s;
                transform: rotate(-90deg);
            }
        }

        & ~ .navbar-collapse {
            @include media('<desktop') {
                display: block;
                overflow: auto;
            }
        }
    }

    &-inner {
        position: absolute;
        display: block;
        top: 50%;
        margin-top: -.2rem;
        width: $btn-menu-width;
        height: .2rem;
        transition-property: transform;
        background-color: $btn-menu-color;
        transition-timing-function: cubic-bezier(.55, .055, .675, .19);
        transition-duration: 75ms;

        &:before,
        &:after {
            content: '';
            display: block;
            position: absolute;
            width: $btn-menu-width;
            height: .2rem;
            transition-timing-function: ease;
            transition-duration: .15s;
            transition-property: transform;
            background-color: inherit;
        }

        &:before {
            top: -1rem;
            transition: top 75ms ease .12s, opacity 75ms ease;
        }

        &:after {
            bottom: -1rem;
            transition: bottom 75ms ease .12s, transform 75ms cubic-bezier(.55, .055, .675, .19);
        }
    }
}
