$icomoon-font-family: 'icomoon' !default;
$icomoon-font-path: 'fonts' !default;

$icon-show: unquote('"\\e914"');
$icon-people-3: unquote('"\\e916"');
$icon-info-i: unquote('"\\e90e"');
$icon-alarm: unquote('"\\e90f"');
$icon-info: unquote('"\\e910"');
$icon-people-2: unquote('"\\e911"');
$icon-people: unquote('"\\e912"');
$icon-profil: unquote('"\\e913"');
$icon-enterprise: unquote('"\\e90d"');
$icon-search: unquote('"\\e90b"');
$icon-document: unquote('"\\e900"');
$icon-player: unquote('"\\e943"');
$icon-player-full: unquote('"\\e944"');
$icon-checked: unquote('"\\e938"');
$icon-close: unquote('"\\e935"');
$icon-arrow-b: unquote('"\\e901"');
$icon-arrow-first: unquote('"\\e902"');
$icon-arrow-l: unquote('"\\e903"');
$icon-arrow-last: unquote('"\\e904"');
$icon-arrow-line-b: unquote('"\\e905"');
$icon-arrow-line-l: unquote('"\\e906"');
$icon-arrow-line-r: unquote('"\\e907"');
$icon-arrow-line-t: unquote('"\\e908"');
$icon-arrow-r: unquote('"\\e909"');
$icon-arrow-t: unquote('"\\e90a"');
$icon-linkedin: unquote('"\\e921"');
$icon-play-circle-line: unquote('"\\e926"');
$icon-rss: unquote('"\\e92a"');
$icon-twitter: unquote('"\\e90c"');
$icon-youtube: unquote('"\\e92f"');
$icon-facebook: unquote('"\\e932"');
$icon-instagram: unquote('"\\e933"');
$icon-share: unquote('"\\e934"');
$icon-eye: unquote('"\\e9ce"');
$icon-eye-blocked: unquote('"\\e9d1"');

