/*
 * Typography
 */

/* Default font */
html {
    font-family: $default-fonts;
    font-size: $root-font-size-ie11; /* IE9-IE11 math fixing. See http://bit.ly/1g4X0bX */
    line-height: 1.5;
}

body {
    font-size: $base-font-size;
    color: var(--grey-800);

    @include media('<tablet') {
        font-size: 1.6rem;
    }
}

%headings {
    @include media('>=tablet') {
        margin: 2.4rem 0;
    }

    font-weight: bold;
    margin: 1.6rem 0;
    line-height: 1.5;
    color: $darkest-grey;

    &:first-child {
        margin-top: 0 !important;
    }
}

strong,
b {
    font-family: $default-fonts!important;
}

h1,
.like-h1 {
    @extend %headings;
    font-size: rem(40px);

    @include media('>=medium') {
        font-size: rem(50px);
    }
}

h2,
.like-h2,
%like-h2 {
    @extend %headings;
    font-size: 2.8rem;

    @include media('>=medium') {
        font-size: 3.4rem;
    }
}

h3,
.like-h3,
%like-h3 {
    @extend %headings;
    font-size: 2.4rem;
    line-height: 1.33;

    @include media('>=medium') {
        font-size: 2.4rem;
    }
}

h4,
.like-h4,
%like-h4 {
    @extend %headings;
    font-size: 1.8rem;
    margin: 0 0 1.6rem;
    //ff gotham
    font-weight: 700;//400
    color: inherit;

    @include media('>=medium') {
        font-size: 1.8rem;
    }
}

h5,
.like-h5 {
    @extend %headings;
    font-size: 1.6rem;
    color: inherit;

    @include media('>=medium') {
        font-size: 1.6rem;
    }
}

h6,
.like-h6 {
    @extend %headings;
    font-size: rem(18px);
}

// link
a {
    display: inline-block;
    color: inherit;
    text-decoration: underline;
    transition: .2s;

    &:hover:not(.btn) {
        // exclude .btn to prevent unwanted color change  on a.btn:hover
        color: $black;
    }
}

.lk-no-underline {
    text-decoration: none;

    a {
        text-decoration: none;
    }
}

@include dark-bg {
    a {
        color: $white;

        &:hover:not(.btn) {
            color: $color-link-hover-dark-bg;
        }
    }
}

ul,
ol,
li {
    margin: 0;
    padding: 0;
}

img {
    height: auto;
    max-width: 100%;
}

/** cf utils/list-utilities **/
ul {
    @extend %bullet-list;
}

ol {
    @extend %ol-list;
}

ul,
ol,
p,
fieldset {
    @include bk-type-txt;
}

hr {
    border: 0;
    border-top: 1px solid $dark-grey;
    margin: rem($spacer-m) 0;
}

pre {
    display: block;
    border: 1px solid #690;
    margin: 0 0 1.5em;

    &[class*='language-'] {
        margin: 0 0 1.5em !important;
    }
}

fieldset {
    legend {
        @extend %like-h3;
    }
}

.blockquote {
    margin: 2.4rem 0;
    padding-left: 4.2rem;
    font-size: 1.6rem;

    &--title {
        font-style: normal;
        font-size: inherit;
        color: inherit;
        margin: 0 0 1rem;

        .icon {
            margin-top: -.1em;
            margin-right: .6rem;
            font-size: 1.5em;
            display: inline-block;
            vertical-align: middle;
        }
    }

    &--content {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            left: -4.2rem;
            top: 0;
            bottom: 0;
            width: .4rem;
            border-radius: 0 .5rem .5rem 0;
            background-color: #006a4e;

        }
    }

    &--footer {
        margin-top: 2.4rem;
        color: var(--grey-700);
        font-size: 1.4rem;
        font-style: italic;
    }
}

.text-uppercase {
    text-transform: uppercase;
}

.fw-400 {
    font-weight: 400;
}

.mb-0 {
    margin-bottom: 0;
}

.mt-0 {
    margin-top: 0;
}

.p-0 {
    padding: 0!important;
}

.custom-list {
    margin: 0 0 3.8rem;
    padding-left: 1.2rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 1.4rem;
    gap: 1rem;

    li {
        margin: 0;
        padding: 0 0 0 3.4rem;
        position: relative;
        color: var(--secondary-3);
        list-style-type: none;

        &:before {
            content: $icon-checked;
            font-family: $icomoon-font-family;
            width: 2.4rem;
            text-align: center;
            font-size: 2.4rem;
            position: absolute;
            line-height: 2.4rem;
            left: 0;
            top: 0;
        }
    }
}

.placeholder {
    color: $color-placeholder;

    .parsley-error & {
        color: $color-error;
    }
}

// Not sur it's necessary now  ::placeholder is fully supported
::placeholder {
    color: $color-placeholder;

    .parsley-error & {
        color: $color-error;
    }
}

@include dark-bg {
    color: $white;
}

::selection {
    background-color: $color-text-selection;
    color: $white;
}

code {
    background-color: $white;
    border-radius: 2px;
    color: $darkest-grey;
    padding: 1px 2px;
}

.row {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: -.8rem;
    width: calc(100% + 1.6rem);

    &.no-wrap {
        flex-wrap: nowrap;
    }

    .col {
        flex: auto 1 1;
        padding: .8rem;

        &-6 {
            flex: 50% 0 0;
            padding: .8rem;

            @include media('<tablet') {
                flex: 100% 0 0;
            }
        }

        &-auto {
            flex: auto 0 0!important;
        }
    }
}

.small {
    font-size: 1.2rem;
    line-height: 1.2;
}
