@import 'variables';

@font-face {
    font-family: '#{$icomoon-font-family}';
    src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?zacpiy');
    src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?zacpiy#iefix') format('embedded-opentype'),
        url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?zacpiy') format('truetype'),
        url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?zacpiy') format('woff'),
        url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?zacpiy##{$icomoon-font-family}') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$icomoon-font-family}' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-show {
    &:before {
        content: $icon-show; 
    }
}

.icon-people-3 {
    &:before {
        content: $icon-people-3; 
    }
}

.icon-info-i {
    &:before {
        content: $icon-info-i; 
    }
}

.icon-alarm {
    &:before {
        content: $icon-alarm; 
    }
}

.icon-info {
    &:before {
        content: $icon-info; 
    }
}

.icon-people-2 {
    &:before {
        content: $icon-people-2; 
    }
}

.icon-people {
    &:before {
        content: $icon-people; 
    }
}

.icon-profil {
    &:before {
        content: $icon-profil; 
    }
}

.icon-enterprise {
    &:before {
        content: $icon-enterprise; 
    }
}

.icon-search {
    &:before {
        content: $icon-search; 
    }
}

.icon-document {
    &:before {
        content: $icon-document; 
    }
}

.icon-player {
    &:before {
        content: $icon-player; 
    }
}

.icon-player-full {
    &:before {
        content: $icon-player-full; 
    }
}

.icon-checked {
    &:before {
        content: $icon-checked; 
    }
}

.icon-close {
    &:before {
        content: $icon-close; 
    }
}

.icon-arrow-b {
    &:before {
        content: $icon-arrow-b; 
    }
}

.icon-arrow-first {
    &:before {
        content: $icon-arrow-first; 
    }
}

.icon-arrow-l {
    &:before {
        content: $icon-arrow-l; 
    }
}

.icon-arrow-last {
    &:before {
        content: $icon-arrow-last; 
    }
}

.icon-arrow-line-b {
    &:before {
        content: $icon-arrow-line-b; 
    }
}

.icon-arrow-line-l {
    &:before {
        content: $icon-arrow-line-l; 
    }
}

.icon-arrow-line-r {
    &:before {
        content: $icon-arrow-line-r; 
    }
}

.icon-arrow-line-t {
    &:before {
        content: $icon-arrow-line-t; 
    }
}

.icon-arrow-r {
    &:before {
        content: $icon-arrow-r; 
    }
}

.icon-arrow-t {
    &:before {
        content: $icon-arrow-t; 
    }
}

.icon-linkedin {
    &:before {
        content: $icon-linkedin; 
    }
}

.icon-play-circle-line {
    &:before {
        content: $icon-play-circle-line; 
    }
}

.icon-rss {
    &:before {
        content: $icon-rss; 
    }
}

.icon-twitter {
    &:before {
        content: $icon-twitter; 
    }
}

.icon-youtube {
    &:before {
        content: $icon-youtube; 
    }
}

.icon-facebook {
    &:before {
        content: $icon-facebook; 
    }
}

.icon-instagram {
    &:before {
        content: $icon-instagram; 
    }
}

.icon-share {
    &:before {
        content: $icon-share; 
    }
}

.icon-eye {
    &:before {
        content: $icon-eye; 
    }
}

.icon-eye-blocked {
    &:before {
        content: $icon-eye-blocked; 
    }
}

